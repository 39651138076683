import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/info";
export const PageQuery_info_nl_privacypolicymdx = graphql`
        query PageQuery_info_nl_privacypolicymdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "nl"}, ns: {in: ["common", "info"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/privacy-policy/"},
                    language: {eq: "nl"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Privacybeleid",
  "path": "/info/privacy-policy",
  "date": "2022-05-04T00:00:00.000Z"
};
const layoutProps = {
  PageQuery_info_nl_privacypolicymdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "privacybeleid",
      "style": {
        "position": "relative"
      }
    }}>{`Privacybeleid`}<a parentName="h1" {...{
        "href": "#privacybeleid",
        "aria-label": "privacybeleid permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Laatst bijgewerkt: 29 September, 2022`}</p>
    <p>{`Dit Privacybeleid beschrijft ons beleid en onze procedures met betrekking tot het verzamelen, gebruiken en openbaar maken van Uw gegevens wanneer U de Dienst gebruikt en informeert U over Uw privacyrechten en hoe de wet U beschermt.`}</p>
    <p>{`Wij gebruiken Uw Persoonsgegevens om de Dienst te verlenen en te verbeteren. Door de Dienst te gebruiken, gaat U akkoord met het verzamelen en gebruiken van informatie in overeenstemming met dit Privacybeleid.`}</p>
    <h1 {...{
      "id": "table-of-contents",
      "style": {
        "position": "relative"
      }
    }}>{`Table of Contents`}<a parentName="h1" {...{
        "href": "#table-of-contents",
        "aria-label": "table of contents permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <div className="table-of-contents">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#privacybeleid"
          }}>{`Privacybeleid`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#definities"
          }}>{`Definities`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#vertaling-en-interpretatie"
          }}>{`Vertaling en Interpretatie`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#gebruik-van-uw-persoonsgegevens"
          }}>{`Gebruik van uw Persoonsgegevens`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#privacy-van-kinderen"
          }}>{`Privacy van kinderen`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#links-naar-andere-websites"
          }}>{`Links naar andere websites`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#wijzigingen-in-dit-privacybeleid"
          }}>{`Wijzigingen in dit Privacybeleid`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#contact"
          }}>{`Contact`}</a></li>
      </ul>
    </div>
    <h1 {...{
      "id": "definities",
      "style": {
        "position": "relative"
      }
    }}>{`Definities`}<a parentName="h1" {...{
        "href": "#definities",
        "aria-label": "definities permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <h2 {...{
      "id": "interpretatie",
      "style": {
        "position": "relative"
      }
    }}>{`Interpretatie`}<a parentName="h2" {...{
        "href": "#interpretatie",
        "aria-label": "interpretatie permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`De woorden waarvan de beginletter met een hoofdletter is geschreven, hebben de betekenis die onder de volgende voorwaarden is gedefinieerd. De volgende definities hebben dezelfde betekenis, ongeacht of ze in het enkelvoud of in het meervoud voorkomen.`}</p>
    <h2 {...{
      "id": "entiteiten",
      "style": {
        "position": "relative"
      }
    }}>{`Entiteiten`}<a parentName="h2" {...{
        "href": "#entiteiten",
        "aria-label": "entiteiten permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`Voor de toepassing van dit Privacybeleid wordt verstaan onder:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tekst`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Betekenis`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Een unieke account die voor U is aangemaakt om toegang te krijgen tot onze Service of delen daarvan.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bedrijf`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In deze Overeenkomst aangeduid als "het Bedrijf", "Wij", "Ons" of "Onze" - verwijst naar Cheesebyte, Keurenplein 4 (A1800), 1069 CD Amsterdam.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookies`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kleine bestanden die door een website op Uw computer, mobiele apparaat of een ander apparaat worden geplaatst en die de gegevens bevatten van Uw browsegeschiedenis op die website, naast de vele andere toepassingen.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Land`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Verwijst naar: Nederland.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Apparaat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ieder apparaat dat toegang kan krijgen tot de Dienst zoals een computer, een mobiele telefoon of een digitale tablet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Persoonsgegevens`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Alle informatie die betrekking heeft op een geïdentificeerde of identificeerbare natuurlijke persoon.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dienst`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Verwijst naar de Website.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dienstverlener`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Iedere natuurlijke of rechtspersoon die de gegevens verwerkt voor rekening van de Onderneming. Hiermee worden derde bedrijven of personen bedoeld die door het Bedrijf zijn aangesteld om de Service te faciliteren, om de Service namens het Bedrijf te verlenen, om diensten met betrekking tot de Service uit te voeren of om het Bedrijf bij te staan bij het analyseren van hoe de Service wordt gebruikt.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gebruiksgegevens`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Verwijst naar gegevens die automatisch worden verzameld, hetzij gegenereerd door het gebruik van de Service, hetzij vanuit de Service-infrastructuur zelf (bijvoorbeeld de duur van een paginabezoek).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Website`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Verwijst naar Cheesebyte, toegankelijk vanaf `}<a parentName="td" {...{
              "href": "https://www.cheesebyte.nl"
            }}>{`www.cheesebyte.nl`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`De persoon die toegang heeft tot of gebruik maakt van de Dienst, of het bedrijf, of een andere juridische entiteit namens welke een dergelijke persoon toegang heeft tot of gebruik maakt van de Dienst, voor zover van toepassing.`}</td>
        </tr>
      </tbody>
    </table>
    <h1 {...{
      "id": "vertaling-en-interpretatie",
      "style": {
        "position": "relative"
      }
    }}>{`Vertaling en Interpretatie`}<a parentName="h1" {...{
        "href": "#vertaling-en-interpretatie",
        "aria-label": "vertaling en interpretatie permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Deze documentatie kan vertaald zijn indien Wij ze voor U beschikbaar hebben gesteld op onze Dienst. U gaat ermee akkoord dat de originele Engelse tekst voorrang heeft in het geval van een geschil.`}</p>
    <h1 {...{
      "id": "gebruik-van-uw-persoonsgegevens",
      "style": {
        "position": "relative"
      }
    }}>{`Gebruik van uw Persoonsgegevens`}<a parentName="h1" {...{
        "href": "#gebruik-van-uw-persoonsgegevens",
        "aria-label": "gebruik van uw persoonsgegevens permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <h2 {...{
      "id": "verzamelde-soorten-gegevens",
      "style": {
        "position": "relative"
      }
    }}>{`Verzamelde soorten gegevens`}<a parentName="h2" {...{
        "href": "#verzamelde-soorten-gegevens",
        "aria-label": "verzamelde soorten gegevens permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <h3 {...{
      "id": "persoonsgegevens",
      "style": {
        "position": "relative"
      }
    }}>{`Persoonsgegevens`}<a parentName="h3" {...{
        "href": "#persoonsgegevens",
        "aria-label": "persoonsgegevens permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h3>
    <p>{`Tijdens het gebruik van onze service kunnen wij u vragen om ons bepaalde persoonlijk identificeerbare informatie te geven die gebruikt kan worden om contact met u op te nemen of u te identificeren. Persoonlijk identificeerbare informatie kan omvatten, maar is niet beperkt tot:`}</p>
    <ul>
      <li parentName="ul">{`E-mailadres`}</li>
      <li parentName="ul">{`Gebruiksgegevens`}</li>
    </ul>
    <h3 {...{
      "id": "gebruiksgegevens",
      "style": {
        "position": "relative"
      }
    }}>{`Gebruiksgegevens`}<a parentName="h3" {...{
        "href": "#gebruiksgegevens",
        "aria-label": "gebruiksgegevens permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h3>
    <p>{`Gebruiksgegevens worden automatisch verzameld tijdens het gebruik van de Dienst.`}</p>
    <p>{`Gebruiksgegevens kunnen informatie bevatten zoals het Internet Protocol-adres van Uw Apparaat (bijv. IP-adres), het type browser, browserversie, de pagina's van onze Service die U bezoekt, de tijd en datum van Uw bezoek, de tijd die U op die pagina's doorbrengt, unieke apparaat-identificaties en andere diagnostische gegevens.`}</p>
    <p>{`Wanneer U toegang verkrijgt tot de Dienst door of via een mobiel apparaat, kunnen wij automatisch bepaalde informatie verzamelen, waaronder, maar niet beperkt tot, het type mobiel apparaat dat U gebruikt, de unieke ID van Uw mobiele apparaat, het IP-adres van Uw mobiele apparaat, Uw mobiele besturingssysteem, het type mobiele internetbrowser dat U gebruikt, unieke apparaat-ID's en andere diagnostische gegevens.`}</p>
    <p>{`Wij kunnen ook informatie verzamelen die Uw browser verstuurt wanneer U onze Dienst bezoekt of wanneer U de Dienst opent met of via een mobiel apparaat.`}</p>
    <h3 {...{
      "id": "tracking-technologies-en-cookies",
      "style": {
        "position": "relative"
      }
    }}>{`Tracking Technologies en Cookies`}<a parentName="h3" {...{
        "href": "#tracking-technologies-en-cookies",
        "aria-label": "tracking technologies en cookies permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h3>
    <p>{`Wij gebruiken Cookies en gelijkaardige traceertechnologieën om de activiteit op onze Service te volgen en bepaalde informatie op te slaan. Tracking technologieën die wij gebruiken zijn beacons, tags en scripts om informatie te verzamelen en te traceren en om onze Service te verbeteren en te analyseren. De technologieën die Wij gebruiken kunnen omvatten:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Cookies of Browsercookies.`}</strong>{` Een cookie is een klein bestand dat op Uw Apparaat wordt geplaatst. U kunt Uw browser instrueren om alle Cookies te weigeren of om aan te geven wanneer er een Cookie wordt verzonden. Als U echter geen Cookies accepteert, kunt U sommige delen van onze Dienst mogelijk niet gebruiken. Tenzij U Uw browser zo hebt ingesteld dat deze Cookies weigert, kan onze Dienst gebruik maken van Cookies.`}</li>
      <li parentName="ul">{`Bepaalde onderdelen van onze Service kunnen gebruik maken van lokaal opgeslagen objecten (of Flash Cookies) om informatie te verzamelen en op te slaan over Uw voorkeuren of Uw activiteiten op onze Service. Flash Cookies worden niet beheerd door dezelfde browserinstellingen als die gebruikt worden voor Browser Cookies. Voor meer informatie over hoe U Flash Cookies kunt verwijderen, leest U `}<a parentName="li" {...{
          "href": "https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
        }}>{`"Waar kan ik de instellingen voor het uitschakelen of verwijderen van lokale gedeelde objecten wijzigen?"`}</a>{`.`}</li>
      <li parentName="ul">{`Bepaalde delen van onze Service en onze e-mails kunnen kleine elektronische bestanden bevatten die bekend staan als webbakens (ook wel clear gifs, pixeltags en single-pixel gifs genoemd), waarmee het Bedrijf bijvoorbeeld gebruikers kan tellen die deze pagina's hebben bezocht of een e-mail hebben geopend en voor andere gerelateerde websitestatistieken (bijvoorbeeld het registreren van de populariteit van een bepaald deel en het verifiëren van systeem- en serverintegriteit).`}</li>
    </ul>
    <p>{`Cookies kunnen "Persistent" of "Session" Cookies zijn. Persistente cookies blijven op Uw computer of mobiele apparaat wanneer U offline gaat, terwijl Sessiecookies worden verwijderd zodra U Uw webbrowser sluit. U kunt hier meer te weten komen over cookies: `}<a parentName="p" {...{
        "href": "https://nl.wikipedia.org/wiki/Cookie_(internet)"
      }}>{`HTTP cookie`}</a>{`.`}</p>
    <p>{`Wij gebruiken zowel Sessiecookies als Persistente cookies voor de hieronder uiteengezette doeleinden:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Noodzakelijke / essentiële cookies`}</strong></p>
        <p parentName="li">{`  Type: Sessiecookies`}</p>
        <p parentName="li">{`  Beheerd door: Ons`}</p>
        <p parentName="li">{`  Doel: Deze Cookies zijn essentieel om U te voorzien van diensten die beschikbaar zijn via de Website en om U in staat te stellen sommige van haar functies te gebruiken. Ze helpen om gebruikers te verifiëren en frauduleus gebruik van gebruikersaccounts te voorkomen. Zonder deze Cookies kunnen de diensten waar U om heeft gevraagd niet worden geleverd, en Wij gebruiken deze Cookies alleen om U van deze diensten te voorzien.
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Cookiebeleid / Kennisgeving Acceptatie Cookies`}</strong></p>
        <p parentName="li">{`  Type: Permanente Cookies`}</p>
        <p parentName="li">{`  Beheerd door: Ons`}</p>
        <p parentName="li">{`  Doel: Deze Cookies identificeren of gebruikers het gebruik van cookies op de Website hebben geaccepteerd.
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Functionaliteitscookies`}</strong></p>
        <p parentName="li">{`  Type: Permanente Cookies`}</p>
        <p parentName="li">{`  Beheerd door: Us`}</p>
        <p parentName="li">{`  Doel: Deze cookies stellen ons in staat om keuzes te onthouden die U maakt wanneer U de Website gebruikt, zoals het onthouden van uw inloggegevens of taalvoorkeur. Het doel van deze Cookies is om U een meer persoonlijke ervaring te bieden en om te voorkomen dat U telkens wanneer U de Website gebruikt opnieuw uw voorkeuren moet invoeren.`}</p>
      </li>
    </ul>
    <p>{`Voor meer informatie over de cookies die wij gebruiken en uw keuzes met betrekking tot cookies, verwijzen wij U naar ons Cookiesbeleid of de sectie Cookies van ons Privacybeleid.`}</p>
    <h2 {...{
      "id": "gebruik-van-uw-persoonsgegevens-1",
      "style": {
        "position": "relative"
      }
    }}>{`Gebruik van uw Persoonsgegevens`}<a parentName="h2" {...{
        "href": "#gebruik-van-uw-persoonsgegevens-1",
        "aria-label": "gebruik van uw persoonsgegevens 1 permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`De Onderneming kan Persoonsgegevens voor de volgende doeleinden gebruiken:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Om onze Service te verlenen en te onderhouden`}</strong>{`, inclusief om het gebruik van onze Service te monitoren.`}</li>
      <li parentName="ul"><strong parentName="li">{`Om Uw Account te beheren:`}</strong>{` om Uw registratie als gebruiker van de Service te beheren. De door U verstrekte Persoonsgegevens kunnen U toegang verschaffen tot verschillende functionaliteiten van de Service die voor U als geregistreerd gebruiker beschikbaar zijn.`}</li>
      <li parentName="ul"><strong parentName="li">{`Voor de uitvoering van een contract:`}</strong>{` de ontwikkeling, naleving en uitvoering van het koopcontract voor de producten, artikelen of diensten die U heeft gekocht of van enig ander contract met Ons via de Dienst.`}</li>
      <li parentName="ul"><strong parentName="li">{`Om contact met U op te nemen:`}</strong>{` Om contact met U op te nemen via e-mail, telefoongesprekken, SMS of andere gelijkwaardige vormen van elektronische communicatie, zoals push-notificaties van een mobiele applicatie met betrekking tot updates of informatieve mededelingen over de functionaliteiten, producten of gecontracteerde diensten, inclusief de beveiligingsupdates, wanneer dit noodzakelijk of redelijk is voor de uitvoering ervan.`}</li>
      <li parentName="ul"><strong parentName="li">{`Om U`}</strong>{` te voorzien van nieuws, speciale aanbiedingen en algemene informatie over andere goederen, diensten en evenementen die wij aanbieden en die vergelijkbaar zijn met de goederen, diensten en evenementen die U reeds hebt gekocht of waarover U reeds informatie hebt ingewonnen, tenzij U ervoor hebt gekozen dergelijke informatie niet te ontvangen.`}</li>
      <li parentName="ul"><strong parentName="li">{`Om uw verzoeken te beheren:`}</strong>{` Om uw verzoeken aan ons bij te wonen en te beheren.`}</li>
      <li parentName="ul"><strong parentName="li">{`Voor bedrijfsoverdrachten:`}</strong>{` Wij kunnen Uw informatie gebruiken om een fusie, afstoting, herstructurering, reorganisatie, ontbinding, of andere verkoop of overdracht van sommige of al onze activa te evalueren of uit te voeren, hetzij als going concern of als onderdeel van een faillissement, liquidatie, of soortgelijke procedure, waarbij Persoonlijke Gegevens in ons bezit over onze Servicegebruikers tot de overgedragen activa behoren.`}</li>
      <li parentName="ul"><strong parentName="li">{`Voor andere doeleinden`}</strong>{`: Wij kunnen Uw gegevens gebruiken voor andere doeleinden, zoals gegevensanalyse, het vaststellen van gebruikstrends, het bepalen van de effectiviteit van onze promotiecampagnes en om onze Service, producten, diensten, marketing en uw ervaring te evalueren en te verbeteren.`}</li>
    </ul>
    <p>{`Wij kunnen Uw persoonlijke informatie in de volgende situaties delen:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Met Dienstverleners:`}</strong>{` Wij kunnen Uw persoonlijke informatie delen met Dienstverleners om het gebruik van onze Service te monitoren en te analyseren, om contact met U op te nemen.`}</li>
      <li parentName="ul"><strong parentName="li">{`Voor bedrijfsoverdrachten:`}</strong>{` Wij kunnen Uw persoonlijke informatie delen of overdragen in verband met, of tijdens onderhandelingen over, een fusie, verkoop van bedrijfsactiva, financiering, of overname van het geheel of een deel van onze activiteiten aan een ander bedrijf.`}</li>
      <li parentName="ul"><strong parentName="li">{`Met filialen:`}</strong>{` Wij kunnen uw informatie delen met onze filialen, in welk geval wij van deze filialen zullen eisen dat zij dit Privacybeleid respecteren. Filialen omvatten onze moedermaatschappij en alle andere dochterondernemingen, joint venture partners of andere bedrijven waarover wij zeggenschap hebben of die onder gemeenschappelijke zeggenschap met ons staan.`}</li>
      <li parentName="ul"><strong parentName="li">{`Met zakenpartners:`}</strong>{` Wij kunnen uw informatie delen met onze zakenpartners om u bepaalde producten, diensten of promoties aan te bieden.`}</li>
      <li parentName="ul"><strong parentName="li">{`Met andere gebruikers:`}</strong>{` wanneer U persoonlijke informatie deelt of op andere wijze interageert in de openbare ruimtes met andere gebruikers, kan deze informatie worden bekeken door alle gebruikers en kan deze openbaar worden verspreid naar buiten.`}</li>
      <li parentName="ul"><strong parentName="li">{`Met Uw toestemming`}</strong>{`: Wij kunnen Uw persoonlijke gegevens voor andere doeleinden openbaar maken met Uw toestemming.`}</li>
    </ul>
    <h2 {...{
      "id": "bewaring-van-uw-persoonlijke-gegevens",
      "style": {
        "position": "relative"
      }
    }}>{`Bewaring van Uw Persoonlijke Gegevens`}<a parentName="h2" {...{
        "href": "#bewaring-van-uw-persoonlijke-gegevens",
        "aria-label": "bewaring van uw persoonlijke gegevens permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`De Onderneming zal Uw Persoonsgegevens niet langer bewaren dan nodig is voor de doeleinden die in dit Privacybeleid zijn uiteengezet. Wij zullen Uw Persoonsgegevens bewaren en gebruiken voor zover dat nodig is om te voldoen aan onze wettelijke verplichtingen (bijvoorbeeld als wij Uw gegevens moeten bewaren om te voldoen aan toepasselijke wetgeving), geschillen op te lossen en onze wettelijke overeenkomsten en beleidsregels af te dwingen.`}</p>
    <p>{`De Onderneming zal ook Gebruiksgegevens bewaren voor interne analysedoeleinden. Gebruiksgegevens worden over het algemeen voor een kortere periode bewaard, behalve wanneer deze gegevens worden gebruikt om de beveiliging te versterken of de functionaliteit van Onze Service te verbeteren, of wanneer Wij wettelijk verplicht zijn om deze gegevens langer te bewaren.`}</p>
    <h2 {...{
      "id": "overdracht-van-uw-persoonlijke-gegevens",
      "style": {
        "position": "relative"
      }
    }}>{`Overdracht van uw persoonlijke gegevens`}<a parentName="h2" {...{
        "href": "#overdracht-van-uw-persoonlijke-gegevens",
        "aria-label": "overdracht van uw persoonlijke gegevens permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`Uw informatie, inclusief Persoonsgegevens, wordt verwerkt in de bedrijfsvestigingen van het Bedrijf en op andere plaatsen waar de bij de verwerking betrokken partijen zich bevinden. Dit betekent dat deze informatie kan worden overgedragen aan - en bewaard op - computers die zich buiten uw staat, provincie, land of ander overheidsgebied bevinden waar de wetten inzake gegevensbescherming kunnen verschillen van die in uw rechtsgebied.`}</p>
    <p>{`Uw instemming met dit Privacybeleid gevolgd door Uw verstrekking van dergelijke informatie vertegenwoordigt Uw instemming met die overdracht.`}</p>
    <p>{`Het Bedrijf zal alle stappen nemen die redelijkerwijs nodig zijn om ervoor te zorgen dat Uw gegevens veilig en in overeenstemming met dit Privacybeleid worden behandeld en er zal geen overdracht van Uw Persoonsgegevens plaatsvinden naar een organisatie of een land tenzij er afdoende controles zijn ingesteld inclusief de beveiliging van Uw gegevens en andere persoonlijke informatie.`}</p>
    <h2 {...{
      "id": "openbaarmaking-van-uw-persoonlijke-gegevens",
      "style": {
        "position": "relative"
      }
    }}>{`Openbaarmaking van uw persoonlijke gegevens`}<a parentName="h2" {...{
        "href": "#openbaarmaking-van-uw-persoonlijke-gegevens",
        "aria-label": "openbaarmaking van uw persoonlijke gegevens permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <h3 {...{
      "id": "zakelijke-transacties",
      "style": {
        "position": "relative"
      }
    }}>{`Zakelijke Transacties`}<a parentName="h3" {...{
        "href": "#zakelijke-transacties",
        "aria-label": "zakelijke transacties permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h3>
    <p>{`Als het Bedrijf betrokken is bij een fusie, overname of verkoop van activa, kunnen Uw Persoonlijke Gegevens worden overgedragen. Wij zullen U verwittigen voordat Uw Persoonlijke Gegevens worden overgedragen en onderworpen worden aan een ander Privacybeleid.`}</p>
    <h3 {...{
      "id": "wetshandhaving",
      "style": {
        "position": "relative"
      }
    }}>{`Wetshandhaving`}<a parentName="h3" {...{
        "href": "#wetshandhaving",
        "aria-label": "wetshandhaving permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h3>
    <p>{`Onder bepaalde omstandigheden kan het Bedrijf verplicht zijn om Uw Persoonsgegevens bekend te maken indien de wet dit vereist of in antwoord op geldige verzoeken van overheidsinstanties (bv. een rechtbank of een overheidsinstantie).`}</p>
    <h3 {...{
      "id": "andere-wettelijke-vereisten",
      "style": {
        "position": "relative"
      }
    }}>{`Andere wettelijke vereisten`}<a parentName="h3" {...{
        "href": "#andere-wettelijke-vereisten",
        "aria-label": "andere wettelijke vereisten permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h3>
    <p>{`Het Bedrijf kan Uw Persoonsgegevens bekendmaken in de oprechte overtuiging dat een dergelijke actie noodzakelijk is om:`}</p>
    <ul>
      <li parentName="ul">{`Voldoen aan een wettelijke verplichting`}</li>
      <li parentName="ul">{`De rechten of het eigendom van het Bedrijf te beschermen en te verdedigen`}</li>
      <li parentName="ul">{`Mogelijke overtredingen in verband met de Service te voorkomen of te onderzoeken`}</li>
      <li parentName="ul">{`De persoonlijke veiligheid van Gebruikers van de Dienst of het publiek te beschermen`}</li>
      <li parentName="ul">{`Beschermen tegen wettelijke aansprakelijkheid`}</li>
    </ul>
    <h2 {...{
      "id": "beveiliging-van-uw-persoonsgegevens",
      "style": {
        "position": "relative"
      }
    }}>{`Beveiliging van Uw Persoonsgegevens`}<a parentName="h2" {...{
        "href": "#beveiliging-van-uw-persoonsgegevens",
        "aria-label": "beveiliging van uw persoonsgegevens permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`De beveiliging van Uw Persoonlijke Gegevens is belangrijk voor Ons, maar onthoud dat geen enkele methode van transmissie over het Internet, of methode van elektronische opslag 100% veilig is. Hoewel Wij ernaar streven om commercieel aanvaardbare middelen te gebruiken om Uw Persoonsgegevens te beschermen, kunnen Wij de absolute veiligheid ervan niet garanderen.`}</p>
    <h1 {...{
      "id": "privacy-van-kinderen",
      "style": {
        "position": "relative"
      }
    }}>{`Privacy van kinderen`}<a parentName="h1" {...{
        "href": "#privacy-van-kinderen",
        "aria-label": "privacy van kinderen permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Onze Dienst richt zich niet tot personen onder de leeftijd van 13 jaar. Wij verzamelen niet bewust persoonlijk identificeerbare informatie van iemand onder de leeftijd van 13. Als U een ouder of voogd bent en U weet dat Uw kind Ons Persoonsgegevens heeft verstrekt, neem dan contact met Ons op. Als Wij er achter komen dat Wij Persoonsgegevens hebben verzameld van iemand die jonger is dan 13 jaar zonder verificatie van ouderlijke toestemming, dan ondernemen Wij stappen om die informatie van Onze servers te verwijderen.`}</p>
    <p>{`Als wij ons moeten baseren op toestemming als rechtsgrondslag voor het verwerken van Uw gegevens en Uw land vereist toestemming van een ouder, dan kunnen Wij de toestemming van Uw ouder nodig hebben voordat Wij die gegevens verzamelen en gebruiken.`}</p>
    <h1 {...{
      "id": "links-naar-andere-websites",
      "style": {
        "position": "relative"
      }
    }}>{`Links naar andere websites`}<a parentName="h1" {...{
        "href": "#links-naar-andere-websites",
        "aria-label": "links naar andere websites permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Onze Service kan links bevatten naar andere websites die niet door Ons worden beheerd. Als U op een link van een derde partij klikt, wordt U naar de site van die derde partij geleid. Wij raden U ten zeerste aan om het Privacybeleid van elke site die U bezoekt door te nemen.`}</p>
    <p>{`Wij hebben geen controle over en nemen geen verantwoordelijkheid voor de inhoud, het privacybeleid of de praktijken van sites of diensten van derden.`}</p>
    <h1 {...{
      "id": "wijzigingen-in-dit-privacybeleid",
      "style": {
        "position": "relative"
      }
    }}>{`Wijzigingen in dit Privacybeleid`}<a parentName="h1" {...{
        "href": "#wijzigingen-in-dit-privacybeleid",
        "aria-label": "wijzigingen in dit privacybeleid permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Wij kunnen ons Privacybeleid van tijd tot tijd bijwerken. Wij zullen u op de hoogte brengen van eventuele wijzigingen door het nieuwe Privacybeleid op deze pagina te plaatsen.`}</p>
    <p>{`Wij kunnen U dit laten weten via e-mail en/of een opvallende kennisgeving op Onze Service, voordat de wijziging van kracht wordt en de "Laatst bijgewerkt"-datum bovenaan dit Privacybeleid bijwerken.`}</p>
    <p>{`U wordt geadviseerd om dit Privacybeleid regelmatig te controleren op eventuele wijzigingen. Wijzigingen in dit Privacybeleid worden van kracht wanneer ze op deze pagina worden geplaatst.`}</p>
    <h1 {...{
      "id": "contact",
      "style": {
        "position": "relative"
      }
    }}>{`Contact`}<a parentName="h1" {...{
        "href": "#contact",
        "aria-label": "contact permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Als u vragen heeft over dit Privacybeleid, kunt u contact met ons opnemen:`}</p>
    <ul>
      <li parentName="ul">{`Per e-mail: zie voettekst op deze website.`}</li>
    </ul>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      